import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Udza0H4-Dqo&">
    <SEO title="Daniel 3 - Living in Babylon" />
  </Layout>
)
export default SermonPost
